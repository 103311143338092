import React from 'react';
import Helmet from '../project/Helmet';
import { withPrefix } from 'gatsby';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class ClaimRulesPage extends React.PureComponent {
    render() {
        const pdf = withPrefix("/pdf/reklamacny-poriadok.pdf");
        return <React.Fragment>
            <Helmet
                title={t`Reklamačný poriadok`}
            />
            <div className="full-size-height">
                <object data={pdf} type="application/pdf" width="100%" height="100%">
                    <p>
                        <Trans>Pre zobrazenie reklamačného poriadku prejdite na </Trans>
                        <a href={pdf}><Trans>PDF.</Trans></a>
                    </p>
                </object>
            </div>
        </React.Fragment>;
    }

}

export default ClaimRulesPage;